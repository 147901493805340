import Link from "next/link";
import styles from "./BreadCrumb.module.scss";

/* -------------
  パンくずリスト
------------- */

interface Bread {
  page: string;
  url?: string;
  textColor?: "white";
}

const BreadCrumb: React.FunctionComponent<{ list: Array<Bread> }> = ({ list }) => (
  <div className={styles.body}>
    <div className="container">
      <ul itemScope itemType="https://schema.org/BreadcrumbList">
        {list.map((l, i) => (
          <li
            itemProp="itemListElement"
            itemScope
            itemType="https://schema.org/ListItem"
            key={`breadcrumb_${i}`}
            className={l.textColor === "white" ? styles.isWhite : ""}
          >
            {list.length > i + 1 && l.url ? (
              <Link itemProp="item" href={l.url} prefetch={false}>
                <span itemProp="name">{l.page}</span>
              </Link>
            ) : (
              <span itemProp="name">{l.page}</span>
            )}
            <meta itemProp="position" content={`${i + 1}`} />
          </li>
        ))}
      </ul>
    </div>
  </div>
);

export default BreadCrumb;
