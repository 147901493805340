"use client";

import { QueryResult } from "@apollo/client";
import Link from "next/link";
import { FC, useEffect, useState } from "react";
import FavoriteRoomCarousel from "@/common/partials/RoomCarousel/FavoriteRoomCarousel";
import carouselStyles from "@/common/partials/RoomCarousel/RoomCarousel.module.scss";
import ViewHistoryCarousel from "@/common/partials/RoomCarousel/ViewHistoryCarousel";
import PropertySearchBar from "@/common/partials/SearchBar/PropertySearchBar";
import BreadCrumb from "@/layouts/BreadCrumb/BreadCrumb";
import Conditions from "@/pages/list/Logic/Condition/Conditions";
import styles from "@/pages/prefecture/Hero/Hero.module.scss";
import Station from "/public/static/svgs/top/station.svg";
import Area from "/public/static/svgs/top/area.svg";
import AppBanner from "@/pages/top/AppBanner/AppBanner";
import PartnerDataSyncBanner from "@/pages/top/PartnerDataSyncBanner/PartnerDataSyncBanner";
import Rankings from "@/pages/top/Rankings/Rankings";
import SwiperLogos from "@/pages/top/SwiperLogos/SwiperLogos";
import { GTMEventClient } from "~/clients/google/gtm/GTMEventClient";
import { SavedSearch } from "~/db/models/SavedSearch";
import { SearchHistory } from "~/db/models/SearchHistory";
import { RoomCountQuery, useRoomCountQuery } from "~/generated/v2/graphql";
import { GraphqlVersion } from "~/lib/apolloClientLinkStrategy";
import { WheresConverter } from "~/queries/converters/v2/searches/WheresConverter";
import { addParameter, removeParameters } from "~/utils/urlUtil";

type Props = {
  prefectureName: string;
  prefectureSlug: string;
  prefectureQuery: Record<string, string>;
};

const PastCriteriaSearchButton: FC<{
  criteria: SearchHistory | SavedSearch;
  label: string;
  newRoomCount?: QueryResult<RoomCountQuery>;
}> = ({ criteria, label, newRoomCount }) => {
  const newRoomCountLoaded =
    newRoomCount && !newRoomCount.loading && newRoomCount.data.room_count > 0;

  return (
    <Link
      className={`${styles.pastCriteria} ${newRoomCountLoaded ? styles.newArrival : ""}`}
      href={addParameter(removeParameters(criteria.path, ["o"]), "o", "arrived")}
      prefetch={false}
      onClick={() => {
        GTMEventClient.searchWithSavedCondition();
      }}
    >
      <p>
        {label}で探す
        {newRoomCountLoaded && (
          <span className={styles.small}> (新着{newRoomCount.data.room_count}件)</span>
        )}
      </p>
      <span className={`${styles.small} ${styles.textOverflow}`}>
        {Object.values(criteria.conditions).join("/")}
      </span>
    </Link>
  );
};

const Hero: FC<Props> = ({ prefectureName, prefectureSlug, prefectureQuery }) => {
  const [newestSavedSearch, setNewestSavedSearch] = useState<SavedSearch>(null);
  const [newestSearchHistory, setNewestSearchHistory] = useState<SearchHistory>(null);

  const getNewestSavedSearch = async () => {
    setNewestSavedSearch(await SavedSearch.getNewest());
  };

  const getNewestSearchHistory = async () => {
    setNewestSearchHistory(await SearchHistory.getNewest());
  };

  useEffect(() => {
    getNewestSavedSearch();
    getNewestSearchHistory();
  }, []);

  const pastCriteria = newestSavedSearch ?? newestSearchHistory;
  const query = Object.fromEntries(
    new URL(pastCriteria?.path, process.env.NEXT_PUBLIC_FRONT_URL).searchParams,
  );

  const newRoomCountResult = useRoomCountQuery({
    fetchPolicy: "no-cache",
    variables: {
      where: WheresConverter.newArrivalRoomCount(Conditions.fromQuery(query)),
    },
    context: { api: GraphqlVersion.V2 },
    skip: !pastCriteria,
  });

  const breadList = [
    { page: "ホーム", url: "/", textColor: "white" as const },
    {
      page: prefectureName + "の賃貸物件を探す",
      textColor: "white" as const,
    },
  ];

  return (
    <>
      <div className={styles.background}>
        <div className="is-pc-only">
          <BreadCrumb list={breadList} />
        </div>
        <div className={styles.container}>
          <div>
            <div className={styles.isFlex}>
              <h2 className={styles.catchText}>
                <span className={styles.prefecture}>{prefectureName}</span>の賃貸物件を探す
              </h2>
              <Link href="/" prefetch={false} className={styles.backButton}>
                都道府県を変更する
              </Link>
            </div>
            <div className={styles.searchLinks}>
              <Link
                className={styles.searchLink}
                href={`/${prefectureSlug}/route/`}
                prefetch={false}
              >
                <Station />
                <span className={styles.text}>
                  <span className={styles.isPcBigText}>路線・駅</span>
                  <span>から探す</span>
                </span>
              </Link>
              <Link
                className={styles.searchLink}
                href={`/${prefectureSlug}/region/`}
                prefetch={false}
              >
                <Area />
                <span className={styles.text}>
                  <span className={styles.isPcBigText}>エリア</span>
                  <span>から探す</span>
                </span>
              </Link>
            </div>
            <div className={styles.searchKeyword}>
              <PropertySearchBar additionalQuery={prefectureQuery} type="top" />
            </div>
            {(newestSavedSearch || newestSearchHistory) && (
              <>
                {newestSavedSearch ? (
                  <PastCriteriaSearchButton
                    criteria={newestSavedSearch}
                    label="保存した条件"
                    newRoomCount={newRoomCountResult}
                  />
                ) : (
                  <PastCriteriaSearchButton
                    criteria={newestSearchHistory}
                    label="前回の条件"
                    newRoomCount={newRoomCountResult}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <PartnerDataSyncBanner />
      <div className="is-mt10">
        <SwiperLogos />
      </div>
      <div className={styles.carouselItem}>
        <FavoriteRoomCarousel
          title={
            <div className={carouselStyles.titleWrapper}>
              <div className="container is-font-bold">
                <h2>お気に入りにしたお部屋</h2>
              </div>
              <Link className={carouselStyles.readAllLink} href={"/mypage/saved/property"}>
                もっと見る
              </Link>
            </div>
          }
        />
      </div>
      <div className={styles.carouselItem}>
        <ViewHistoryCarousel
          title={
            <div className={carouselStyles.titleWrapper}>
              <div className="container is-font-bold">
                <h2>最近見たお部屋</h2>
              </div>
              <Link className={carouselStyles.readAllLink} href={"/mypage/history/property"}>
                もっと見る
              </Link>
            </div>
          }
        />
      </div>
      <div className="is-pc-only">
        <AppBanner />
      </div>
      <Rankings prefectureName={prefectureName} />
    </>
  );
};

export default Hero;
